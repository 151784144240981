<template>
  <Badge
    :class="$style.container"
    :extra-small="extraSmall"
    :text="text"
  />
</template>

<script>
export default {
  name: 'EstimatedBadge',

  props: {
    extraSmall: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: 'Estimated',
    },
  },
};
</script>

<style module>
.container {
  background-color: white;
  border-color: var(--badge-yellow);
  color: var(--badge-yellow);
}

:global([data-theme="dark"]) .container,
:global([data-color-theme="dark"]) .container {
  background-color: black;
  color: var(--badge-yellow);
}
</style>
